<template>
  <v-layout>
    <v-snackbar
      class="snackbarStyles"
      v-model="displayMessage"
      :color="color"
      bottom="bottom"
      :timeout="timeout"
      left
      :multi-line="true"
      vertical="vertical"
    >
      <div v-for="(message, index) in messages" :key="index">
        <div>{{message}}</div>
      </div>
    </v-snackbar>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  page: {
    title: '',
  },
  data() {
    return {
      dialog: false,
      messageDetails: '',
      timeout: 6000,
    }
  },
  filters: {
    snippet(text) {
      if (text.toString().length > 80) {
        return text.toString().substring(0, 80) + '...'
      } else {
        return text
      }
    },
  },
  computed: {
    ...mapGetters('messages', ['messages', 'messageText', 'color']),
    displayMessage: {
      get() {
        return this.$store.state.messages.displayMessage
      },
      set(newValue) {
        this.$store.dispatch('messages/setDisplayMessage', newValue)
      },
    },
  },
  methods: {
    closeMessage() {
      this.displayMessage = false
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>