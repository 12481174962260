import Vue from 'vue'

const document = {
  state: {
    document: [],
    selectedDocument: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    document(state) {
      return state.document
    },
    selectedDocument(state) {
      return state.selectedDocument
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    document(state, payload) {
      state.document = payload
    },
    selectedDocument(state, payload) {
      state.selectedDocument = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedDocument(context, payload) {
      context.commit('selectedDocument', payload)
    },
    loadDocument(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Document'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('document', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedDocument(context, payload) {
      if (context.state.selectedDocument && context.state.selectedDocument.ID) {
        context.dispatch('loadSelectedDocument', context.state.selectedDocument.ID)
      }
    },
    loadSelectedDocument(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Document/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedDocument', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadDocument(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadDocument')
      }, )
    },
    updateDocument(context, payload){
      return new Promise((resolve, reject) => {
        const url = `Document/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Document updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadDocument')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createDocument(context, payload){
      return new Promise((resolve, reject) => {
        const url = `Document`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Document created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadDocument')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveDocument(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateDocument', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createDocument', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteDocument(context, payload){
      return new Promise((resolve, reject) => {
        const url = `Document/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Document deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadDocument')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default document