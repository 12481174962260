<!-- FORM FIELDS LAYOUT-->
<template>
  <v-row>
    <v-col sm="12">
      <v-card class="pa-4">
        <!-- ADD CARD -->
        <v-card-title>This will be where my fields are located!</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col sm="12" md="6" class="pr-2">
                <v-text-field v-model="name" :rules="nameRules" label="First Name" required></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <!-- UPDATE RULES!!! -->
                <v-text-field v-model="lastName" :rules="nameRules" label="Last Name" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="4" class="pr-2">
                <v-text-field v-model="address" label="Address" required></v-text-field>
              </v-col>
              <v-col sm="12" md="4" class="pr-2">
                <!-- UPDATE RULES & MAKE IT A DROPDOWN!!! -->
                <v-text-field v-model="city" :rules="emailRules" label="City" required></v-text-field>
              </v-col>
              <v-col sm="12" md="4">
                <!-- UPDATE RULES!!! -->
                <v-text-field v-model="zip" :rules="emailRules" label="Zip" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6" class="pr-2">
                <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <!-- UPDATE RULES!!! -->
                <v-text-field v-model="password" :rules="emailRules" label="Password" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6" class="pr-2">
                <v-select
                  v-model="select"
                  :items="items"
                  :rules="[v => !!v || 'Item is required']"
                  label="Pick one from a pre-defined list"
                  persistent-hint
                  required
                ></v-select>
              </v-col>
              <v-col sm="12" md="6">
                <v-combobox
                  :items="items"
                  :search-input.sync="search"
                  :hide-selected="hideSelected"
                  label="Pick multiple from the list, or enter your own"
                  :multiple="multiple"
                  persistent-hint
                  :small-chips="chips"
                  :clearable="clearable"
                  deletable-chips
                ></v-combobox>
              </v-col>
            </v-row>
            <!-- ADD MORE USEFULL FIELDS & FEATURES -->
            <v-row>
              <v-col sm="12">
                <v-file-input multiple label="Upload a file" show-size></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <v-checkbox
                  v-model="checkbox"
                  :rules="[v => !!v || 'You must agree to continue!']"
                  label="Do you agree?"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-card-actions class="mt-4">
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Validate</v-btn>

              <v-btn color="error" class="mr-4" @click="reset">Reset Form</v-btn>

              <v-btn color="warning" @click="resetValidation">Reset Validation</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
export default {
  data() {
    return {
      valid: true,
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      lastName: '',
      address: '',
      city: '',
      zip: '',
      email: '',
      password: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
      checkbox: false,
      search: null,
      chips: true,
      multiple: true,
      hideSelected: true,
      noData: true,
      clearable: true,
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>


<!-- STYLES -->
<style lang="scss" module>
@import '@design';
</style>
