<template>
  <span>
    <span v-show="!isLoggedIn && singleProvider == true"
      ><GoogleSignin></GoogleSignin>
    </span>
    <span v-if="!isLoggedIn && !singleProvider && !($route.name == 'login')">
      <v-btn
        color="background"
        :dark="$vuetify.theme.dark ? true : false"
        :light="$vuetify.theme.dark ? false : true"
        @click="goToLogin"
      >
        <v-icon>icon-cowboy</v-icon>
        &nbsp;Sign In
      </v-btn>
    </span>
    <Logout v-if="isLoggedIn"></Logout>
  </span>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
import GoogleSignin from '@components/login/google-signin'
import Logout from '@components/login/logout'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    GoogleSignin,
    Logout,
  },
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
    goToLogin() {
      this.$router.push({ name: 'login' })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
