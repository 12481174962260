import Vue from 'vue'

const luHempOwnership = {
  state: {
    luHempOwnership: [],
    selectedLuHempOwnership: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luHempOwnership(state) {
      return state.luHempOwnership
    },
    selectedLuHempOwnership(state) {
      return state.selectedLuHempOwnership
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luHempOwnership(state, payload) {
      state.luHempOwnership = payload
    },
    selectedLuHempOwnership(state, payload) {
      state.selectedLuHempOwnership = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuHempOwnership(context, payload) {
      context.commit('selectedLuHempOwnership', payload)
    },
    loadLuHempOwnership(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuHempOwnership'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luHempOwnership', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuHempOwnership(context, payload) {
      if (context.state.selectedLuHempOwnership && context.state.selectedLuHempOwnership.Id) {
        context.dispatch('loadSelectedLuHempOwnership', context.state.selectedLuHempOwnership.Id)
      }
    },
    loadSelectedLuHempOwnership(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuHempOwnership/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuHempOwnership', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuHempOwnership(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuHempOwnership')
      }, )
    },
    updateLuHempOwnership(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuHempOwnership/${ payload.Id }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Hemp Ownership updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuHempOwnership')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuHempOwnership(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuHempOwnership`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Hemp Ownership created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuHempOwnership')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuHempOwnership(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.Id > 0) {
          context.dispatch('updateLuHempOwnership', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuHempOwnership', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuHempOwnership(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuHempOwnership/${ payload.Id }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Hemp Ownership deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuHempOwnership')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luHempOwnership