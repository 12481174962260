import Vue from 'vue'

const luDocumentType = {
  state: {
    luDocumentType: [],
    selectedLuDocumentType: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luDocumentType(state) {
      return state.luDocumentType
    },
    selectedLuDocumentType(state) {
      return state.selectedLuDocumentType
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luDocumentType(state, payload) {
      state.luDocumentType = payload
    },
    selectedLuDocumentType(state, payload) {
      state.selectedLuDocumentType = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuDocumentType(context, payload) {
      context.commit('selectedLuDocumentType', payload)
    },
    loadLuDocumentType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuDocumentType'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luDocumentType', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuDocumentType(context, payload) {
      if (context.state.selectedLuDocumentType && context.state.selectedLuDocumentType.ID) {
        context.dispatch('loadSelectedLuDocumentType', context.state.selectedLuDocumentType.ID)
      }
    },
    loadSelectedLuDocumentType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuDocumentType/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuDocumentType', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuDocumentType(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuDocumentType')
      }, )
    },
    updateLuDocumentType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuDocumentType/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Document Type updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuDocumentType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuDocumentType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuDocumentType`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Document Type created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuDocumentType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuDocumentType(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuDocumentType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuDocumentType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuDocumentType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuDocumentType/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Document Type deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuDocumentType')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luDocumentType