import Vue from 'vue'

const luLicenseSubType = {
  state: {
    luLicenseSubType: [],
    selectedLuLicenseSubType: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luLicenseSubType(state) {
      return state.luLicenseSubType
    },
    selectedLuLicenseSubType(state) {
      return state.selectedLuLicenseSubType
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luLicenseSubType(state, payload) {
      state.luLicenseSubType = payload
    },
    selectedLuLicenseSubType(state, payload) {
      state.selectedLuLicenseSubType = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuLicenseSubType(context, payload) {
      context.commit('selectedLuLicenseSubType', payload)
    },
    loadLuLicenseSubType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuLicenseSubType'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luLicenseSubType', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuLicenseSubType(context, payload) {
      if (context.state.selectedLuLicenseSubType && context.state.selectedLuLicenseSubType.ID) {
        context.dispatch('loadSelectedLuLicenseSubType', context.state.selectedLuLicenseSubType.ID)
      }
    },
    loadSelectedLuLicenseSubType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuLicenseSubType/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuLicenseSubType', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuLicenseSubType(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuLicenseSubType')
      }, )
    },
    updateLuLicenseSubType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseSubType/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Sub Type updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseSubType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuLicenseSubType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseSubType`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Sub Type created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseSubType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuLicenseSubType(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuLicenseSubType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuLicenseSubType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuLicenseSubType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseSubType/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Sub Type deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseSubType')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luLicenseSubType