import Vue from 'vue'

const luState = {
  state: {
    luState: [],
    selectedLuState: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luState(state) {
      return state.luState
    },
    selectedLuState(state) {
      return state.selectedLuState
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luState(state, payload) {
      state.luState = payload
    },
    selectedLuState(state, payload) {
      state.selectedLuState = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuState(context, payload) {
      context.commit('selectedLuState', payload)
    },
    loadLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuState'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luState', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuState(context, payload) {
      if (context.state.selectedLuState && context.state.selectedLuState.ID) {
        context.dispatch('loadSelectedLuState', context.state.selectedLuState.ID)
      }
    },
    loadSelectedLuState(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuState/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuState', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuState(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuState')
      }, )
    },
    updateLuState(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuState/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu State updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuState')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuState(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuState`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu State created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuState')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuState(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuState', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuState', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuState(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuState/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu State deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuState')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luState