import Vue from 'vue'

const hempLicenseArea = {
  state: {
    hempLicenseArea: [],
    selectedHempLicenseArea: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    hempLicenseArea(state) {
      return state.hempLicenseArea
    },
    selectedHempLicenseArea(state) {
      return state.selectedHempLicenseArea
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    hempLicenseArea(state, payload) {
      state.hempLicenseArea = payload
    },
    selectedHempLicenseArea(state, payload) {
      state.selectedHempLicenseArea = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedHempLicenseArea(context, payload) {
      context.commit('selectedHempLicenseArea', payload)
    },
    loadHempLicenseArea(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HempLicenseArea'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('hempLicenseArea', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedHempLicenseArea(context, payload) {
      if (context.state.selectedHempLicenseArea && context.state.selectedHempLicenseArea.ID) {
        context.dispatch('loadSelectedHempLicenseArea', context.state.selectedHempLicenseArea.ID)
      }
    },
    loadSelectedHempLicenseArea(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HempLicenseArea/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedHempLicenseArea', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadHempLicenseArea(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadHempLicenseArea')
      }, )
    },
    updateHempLicenseArea(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicenseArea/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License Area updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicenseArea')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createHempLicenseArea(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicenseArea`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License Area created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicenseArea')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveHempLicenseArea(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateHempLicenseArea', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createHempLicenseArea', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteHempLicenseArea(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicenseArea/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License Area deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicenseArea')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default hempLicenseArea