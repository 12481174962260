import Vue from 'vue'

const hempLicense = {
  state: {
    hempLicense: [],
    selectedHempLicense: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    hempLicense(state) {
      return state.hempLicense
    },
    selectedHempLicense(state) {
      return state.selectedHempLicense
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    hempLicense(state, payload) {
      state.hempLicense = payload
    },
    selectedHempLicense(state, payload) {
      state.selectedHempLicense = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedHempLicense(context, payload) {
      context.commit('selectedHempLicense', payload)
    },
    loadHempLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HempLicense'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('hempLicense', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedHempLicense(context, payload) {
      console.log('refresh selected')
      if (context.state.selectedHempLicense && context.state.selectedHempLicense.ID) {
        context.dispatch('loadSelectedHempLicense', context.state.selectedHempLicense.ID)
      }
    },
    loadSelectedHempLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HempLicense/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedHempLicense', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadHempLicense(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadHempLicense')
      }, )
    },
    updateHempLicense(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicense/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicense')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createHempLicense(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicense`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicense')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveHempLicense(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateHempLicense', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createHempLicense', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteHempLicense(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicense/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicense')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default hempLicense