import Vue from 'vue'
import {
  debounce,
  range
} from 'lodash'
const searchSortByDefault = "BusinessName"


const licenseInbox = {
  state: {
    licenseInbox: [],
    selectedLicenseInbox: {},
    searchTotal: 0,
    searchCount: 0,
    searchPage: 1,
    searchSortBy: searchSortByDefault,
    searchDescending: true,
    searchRowsPerPage: 25,
    searchText: '',
    showAllLicenses: false,
    lookupText: '',
    saving: false,
  },

  getters: {
    licenseInbox(state) {
      return state.licenseInbox
    },
    searchParameters(state) {
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection: state.searchDescending ? 'desc' : 'asc',
        searchText: state.searchText,
        showAllLicenses: state.showAllLicenses
      }
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    licenseInbox(state, payload) {
      state.licenseInbox = payload
    },
    selectedLicenseInbox(state, payload) {
      state.selectedLicenseInbox = JSON.parse(JSON.stringify(payload))
    },
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    showAllLicenses(state, payload) {
      state.showAllLicenses = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    saving(state, payload) {
      state.saving = payload
    },
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupLicenseInbox')
    }, 300),
    bounce: _.debounce((context) => {
      context.dispatch('searchLicenseInbox')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('bounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (payload != context.state.searchSortBy) {
        context.commit('searchSortBy', payload)
        context.dispatch('bounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (payload != context.state.searchDescending) {
        context.commit('searchDescending', payload)
        context.dispatch('bounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('bounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('bounce', context)
      }
    },
    setShowAllLicenses(context, payload) {
      if (payload != context.state.showAllLicenses) {
        context.commit('showAllLicenses', payload)
        context.dispatch('bounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    setSelectedLicenseInbox(context, payload) {
      context.commit('selectedLicenseInbox', payload)
    },
    loadLicenseInbox(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseInbox'
        Vue.prototype.$axios
          .get(url)
          .then(
            (res) => {
              context.commit('licenseInbox', res.data)
              resolve(res)
            },
            (err) => {
              console.log(err)
              context.dispatch('errors/handleError', err, {
                root: true
              })
              reject(err)
            },
          )
      })
    },
    searchLicenseInbox(context, payload) {
      return new Promise((resolve, reject) => {
        if (!context.state.searchSortBy) {
          context.commit('searchSortBy', searchSortByDefault)
          context.commit('searchDescending', true)
        }
        if (Array.isArray(context.state.searchSortBy)) {
          if (context.state.searchSortBy.length > 0) {
            context.commit('searchSortBy', context.state.searchSortBy[0])
            context.commit(
              'searchDescending',
              context.state.searchDescending[0]
            )
          } else {
            context.commit('searchSortBy', searchSortByDefault)
            context.commit('searchDescending', true)
          }
        }
        if (!context.state.searchText) {
          context.commit('searchText', '')
        }
        context.commit('increaseSearchCount')
        const url = `LicenseInbox/Search?searchParams=${JSON.stringify(
          context.getters.searchParameters
        )}`
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('licenseInbox', res.data.Entries)
            context.commit('searchRowsPerPage', res.data.Page.Size)
            context.commit('searchPage', res.data.Page.Number + 1)
            context.commit('searchTotal', res.data.Page.TotalElements)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, {
              root: true
            })
            context.commit('decreaseSearchCount')
            reject(err)
          }
        )
      })
    },
    lookupLicenseInbox(context, payload) {
      if (context.state.lookupText) {
        return new Promise((resolve, reject) => {
          context.commit('increaseSearchCount')
          const url = `LicenseInbox/Lookup?searchText=${context.state.lookupText}`
          Vue.prototype.$axios.get(url).then(
            (res) => {
              context.commit('licenseInbox', res.data)
              context.commit('decreaseSearchCount')
              resolve(res)
            },
            (err) => {
              console.log(err)
              context.dispatch('errors/handleError', err, {
                root: true
              })
              context.commit('decreaseSearchCount')
              reject(err)
            }
          )
        })
      }
    },
  },
}

export default licenseInbox