import Vue from 'vue'

const luTemplateDocuments = {
  state: {
    luTemplateDocuments: [],
    selectedLuTemplateDocuments: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luTemplateDocuments(state) {
      return state.luTemplateDocuments
    },
    selectedLuTemplateDocuments(state) {
      return state.selectedLuTemplateDocuments
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luTemplateDocuments(state, payload) {
      state.luTemplateDocuments = payload
    },
    selectedLuTemplateDocuments(state, payload) {
      state.selectedLuTemplateDocuments = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuTemplateDocuments(context, payload) {
      context.commit('selectedLuTemplateDocuments', payload)
    },
    loadLuTemplateDocuments(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuTemplateDocuments'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luTemplateDocuments', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuTemplateDocuments(context, payload) {
      if (context.state.selectedLuTemplateDocuments && context.state.selectedLuTemplateDocuments.ID) {
        context.dispatch('loadSelectedLuTemplateDocuments', context.state.selectedLuTemplateDocuments.ID)
      }
    },
    loadSelectedLuTemplateDocuments(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuTemplateDocuments/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuTemplateDocuments', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuTemplateDocuments(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuTemplateDocuments')
      }, )
    },
    updateLuTemplateDocuments(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuTemplateDocuments/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Template Documents updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuTemplateDocuments')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuTemplateDocuments(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuTemplateDocuments`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Template Documents created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuTemplateDocuments')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuTemplateDocuments(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuTemplateDocuments', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuTemplateDocuments', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuTemplateDocuments(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuTemplateDocuments/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Template Documents deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuTemplateDocuments')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luTemplateDocuments