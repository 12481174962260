import Vue from 'vue'

const hempLicenseDocument = {
  state: {
    hempLicenseDocument: [],
    selectedHempLicenseDocument: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    hempLicenseDocument(state) {
      return state.hempLicenseDocument
    },
    selectedHempLicenseDocument(state) {
      return state.selectedHempLicenseDocument
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    hempLicenseDocument(state, payload) {
      state.hempLicenseDocument = payload
    },
    selectedHempLicenseDocument(state, payload) {
      state.selectedHempLicenseDocument = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedHempLicenseDocument(context, payload) {
      context.commit('selectedHempLicenseDocument', payload)
    },
    loadHempLicenseDocument(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HempLicenseDocument'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('hempLicenseDocument', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedHempLicenseDocument(context, payload) {
      if (context.state.selectedHempLicenseDocument && context.state.selectedHempLicenseDocument.ID) {
        context.dispatch('loadSelectedHempLicenseDocument', context.state.selectedHempLicenseDocument.ID)
      }
    },
    loadSelectedHempLicenseDocument(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'HempLicenseDocument/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedHempLicenseDocument', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadHempLicenseDocument(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadHempLicenseDocument')
      }, )
    },
    updateHempLicenseDocument(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicenseDocument/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License Document updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicenseDocument')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createHempLicenseDocument(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicenseDocument`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License Document created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicenseDocument')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveHempLicenseDocument(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateHempLicenseDocument', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createHempLicenseDocument', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteHempLicenseDocument(context, payload){
      return new Promise((resolve, reject) => {
        const url = `HempLicenseDocument/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Hemp License Document deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadHempLicenseDocument')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default hempLicenseDocument