import Vue from 'vue'

const luLicenseType = {
  state: {
    luLicenseType: [],
    selectedLuLicenseType: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luLicenseType(state) {
      return state.luLicenseType
    },
    selectedLuLicenseType(state) {
      return state.selectedLuLicenseType
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luLicenseType(state, payload) {
      state.luLicenseType = payload
    },
    selectedLuLicenseType(state, payload) {
      state.selectedLuLicenseType = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuLicenseType(context, payload) {
      context.commit('selectedLuLicenseType', payload)
    },
    loadLuLicenseType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuLicenseType'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luLicenseType', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuLicenseType(context, payload) {
      if (context.state.selectedLuLicenseType && context.state.selectedLuLicenseType.ID) {
        context.dispatch('loadSelectedLuLicenseType', context.state.selectedLuLicenseType.ID)
      }
    },
    loadSelectedLuLicenseType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuLicenseType/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuLicenseType', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuLicenseType(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuLicenseType')
      }, )
    },
    updateLuLicenseType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseType/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Type updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuLicenseType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseType`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Type created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuLicenseType(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuLicenseType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuLicenseType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuLicenseType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseType/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Type deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseType')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luLicenseType