import Vue from 'vue'

const licenseStatus = {
  state: {
    licenseStatus: [],
    selectedLicenseStatus: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    licenseStatus(state) {
      return state.licenseStatus
    },
    selectedLicenseStatus(state) {
      return state.selectedLicenseStatus
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    licenseStatus(state, payload) {
      state.licenseStatus = payload
    },
    selectedLicenseStatus(state, payload) {
      state.selectedLicenseStatus = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLicenseStatus(context, payload) {
      context.commit('selectedLicenseStatus', payload)
    },
    loadLicenseStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseStatus'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('licenseStatus', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLicenseStatus(context, payload) {
      if (context.state.selectedLicenseStatus && context.state.selectedLicenseStatus.ID) {
        context.dispatch('loadSelectedLicenseStatus', context.state.selectedLicenseStatus.ID)
      }
    },
    loadSelectedLicenseStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseStatus/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLicenseStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLicenseStatus(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLicenseStatus')
      }, )
    },
    updateLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseStatus/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Status updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseStatus')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseStatus`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Status created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseStatus')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLicenseStatus', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLicenseStatus', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseStatus/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Status deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseStatus')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default licenseStatus