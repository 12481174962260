<!-- BODY LAYOUT -->
<template>
  <div>
    <!-- <v-app> -->
    <NavBar />
    <ErrorDisplay />
    <ToastDisplay />
    <slot />
    <!-- </v-app> -->
  </div>
</template>


<!-- SCRIPTS -->
<script>
import NavBar from '@components/nav-bar' /*  */
import ErrorDisplay from '@components/error-display' /*  */
import ToastDisplay from '@components/toast-display' /*  */

export default {
  components: { NavBar, ErrorDisplay, ToastDisplay },
}
</script>

<!-- STYLES -->
<style lang="scss">
@import '@design';
</style>



