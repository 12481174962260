import Vue from 'vue'

const licenseNotes = {
  state: {
    licenseNotes: [],
    selectedLicenseNotes: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    licenseNotes(state) {
      return state.licenseNotes
    },
    selectedLicenseNotes(state) {
      return state.selectedLicenseNotes
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    licenseNotes(state, payload) {
      state.licenseNotes = payload
    },
    selectedLicenseNotes(state, payload) {
      state.selectedLicenseNotes = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLicenseNotes(context, payload) {
      context.commit('selectedLicenseNotes', payload)
    },
    loadLicenseNotes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseNotes'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('licenseNotes', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLicenseNotes(context, payload) {
      if (context.state.selectedLicenseNotes && context.state.selectedLicenseNotes.ID) {
        context.dispatch('loadSelectedLicenseNotes', context.state.selectedLicenseNotes.ID)
      }
    },
    loadSelectedLicenseNotes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseNotes/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLicenseNotes', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLicenseNotes(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLicenseNotes')
      }, )
    },
    updateLicenseNotes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseNotes/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Notes updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseNotes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLicenseNotes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseNotes`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Notes created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseNotes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLicenseNotes(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLicenseNotes', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLicenseNotes', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLicenseNotes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseNotes/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Notes deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseNotes')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default licenseNotes