import Vue from 'vue'

const userBusiness = {
  state: {
    userBusiness: [],
    selectedUserBusiness: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    userBusiness(state) {
      return state.userBusiness
    },
    selectedUserBusiness(state) {
      return state.selectedUserBusiness
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    userBusiness(state, payload) {
      state.userBusiness = payload
    },
    selectedUserBusiness(state, payload) {
      state.selectedUserBusiness = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedUserBusiness(context, payload) {
      context.commit('selectedUserBusiness', payload)
    },
    loadUserBusiness(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'UserBusiness'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('userBusiness', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedUserBusiness(context, payload) {
      if (context.state.selectedUserBusiness && context.state.selectedUserBusiness.ID) {
        context.dispatch('loadSelectedUserBusiness', context.state.selectedUserBusiness.ID)
      }
    },
    loadSelectedUserBusiness(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'UserBusiness/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedUserBusiness', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadUserBusiness(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadUserBusiness')
      }, )
    },
    updateUserBusiness(context, payload){
      return new Promise((resolve, reject) => {
        const url = `UserBusiness/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'User Business updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadUserBusiness')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createUserBusiness(context, payload){
      return new Promise((resolve, reject) => {
        const url = `UserBusiness`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'User Business created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadUserBusiness')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveUserBusiness(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateUserBusiness', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createUserBusiness', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteUserBusiness(context, payload){
      return new Promise((resolve, reject) => {
        const url = `UserBusiness/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'User Business deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadUserBusiness')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default userBusiness