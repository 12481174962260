<!-- NAV LAYOUT -->
<template>
  <div class="shellStyles">
    <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini">
      <v-list dense>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon title="Expand Toolbar">domain</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="noHover">
            <v-list-item-title class="text-uppercase"
              >Agriculture Licensing</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn icon @click.stop="mini = !mini" small>
              <v-icon title="Condense Toolbar">chevron_left</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click.stop
          v-for="item in routes"
          :key="item.title"
          :to="item.name"
        >
          <v-list-item-action>
            <v-icon :title="item.title">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dark class="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon v-if="!drawer" title="Show Toolbar">menu</v-icon>
        <v-icon v-if="drawer" title="Hide Toolbar">menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="headline text-uppercase">
        <span>AGRICULTURE&nbsp;</span>
        <span class="font-weight-light">
          Licensing
          <!-- <span v-if="appInfo" class="caption"
            >&nbsp;Version {{ version }}</span
          >-->
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <SignIn></SignIn>
    </v-app-bar>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import { authComputed } from '@state/helpers'
import NavBarRoutes from './nav-bar-routes'
import SignIn from '@components/login/sign-in'

export default {
  components: { NavBarRoutes, SignIn },
  data() {
    return {
      drawer: null,
      mini: true,
      installBtn: 'none',
      installer: undefined,
      version: VUE_APP_VERSION,

      persistentNavRoutes: [
        {
          name: 'home',
          title: 'Home',
        },
        {
          name: 'about',
          title: 'About',
        },
      ],
      loggedInNavRoutes: [
        //we don't have a current user object yet
        // {
        //   name: 'profile',
        //   title: () => 'Logged in as ' + this.currentUser.name,
        // },
        {
          name: 'tickets',
          title: 'Tickets',
        },
        {
          name: 'values',
          title: 'Values',
        },
        {
          path: '/logout',
          title: 'Log Out',
        },
      ],
      loggedOutNavRoutes: [
        {
          name: 'login',
          title: 'Log in',
        },
      ],
    }
  },
  watch: {
    isLoggedIn(nval, oval) {
      if (nval) {
        if (this.$route.query != {} && this.$route.query.redirectFrom) {
          this.$router.push(this.$route.query.redirectFrom)
        } else {
          if (this.isInternalUser) {
            // or isUser
            //redirect to inbox
            this.$router.push({ path: '/applicationInbox' })
          } else {
            if (this.isBusinessUser) {
              this.$router.push({ path: '/userBusiness' })
            }
          }
        }
      } else {
        if (this.$router.history.current.name != 'login') {
          this.$router.push({
            name: 'login',
            query: {
              redirectFrom: this.$router.history.current.path,
            },
          })
        }
      }
    },
  },
  computed: {
    ...authComputed,
    routes() {
      var routes = []
      if (this.isLoggedIn) {
        routes = [
          { name: '/', title: 'Home', icon: 'home' },
          // {
          //   name: '/accountCreation',
          //   title: 'Create an Account',
          //   icon: 'mdi-account-plus',
          // },

          {
            name: '/applicationInstructions',
            title: 'Application Instructions',
            icon: 'mdi-apps-box',
          },
        ]
        if (this.isBusinessUser) {
          routes.push({
            name: '/userBusiness',
            title: 'User Account',
            icon: 'mdi-account-circle',
          })
        }
        if (this.isInternalUser) {
          routes.push(
            {
              name: '/applicationInbox',
              title: 'Applications Inbox',
              icon: 'mdi-inbox-full',
            },
            {
              name: '/adhocReport',
              title: 'Adhoc Report',
              icon: 'mdi-file-chart',
            }
          )
        }
        if (this.isAdmin) {
          routes.push({
            name: '/admin',
            title: 'Admin',
            icon: 'mdi-account-supervisor-circle',
          })
        }
        routes.push({ name: '/logout', title: 'Log Out', icon: 'lock_open' })
      } else {
        routes = [
          { name: '/', title: 'Home', icon: 'home' },
          {
            name: '/applicationInstructions',
            title: 'Application Instructions',
            icon: 'mdi-apps-box',
          },
          { name: '/login', title: 'Login', icon: 'lock' },
        ]
      }
      return routes
    },
  },
  created() {
    let installPrompt

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      installPrompt = e
      this.installBtn = 'inline-block'
    })

    this.installer = () => {
      this.installBtn = 'none'
      installPrompt.prompt()
      installPrompt.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('Install accepted!')
        } else {
          console.log('Install denied!')
        }
      })
    }
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
@import '@design';
</style>
