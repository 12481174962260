import Vue from 'vue'

const luLicenseStatus = {
  state: {
    luLicenseStatus: [],
    selectedLuLicenseStatus: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luLicenseStatus(state) {
      return state.luLicenseStatus
    },
    selectedLuLicenseStatus(state) {
      return state.selectedLuLicenseStatus
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luLicenseStatus(state, payload) {
      state.luLicenseStatus = payload
    },
    selectedLuLicenseStatus(state, payload) {
      state.selectedLuLicenseStatus = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuLicenseStatus(context, payload) {
      context.commit('selectedLuLicenseStatus', payload)
    },
    loadLuLicenseStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuLicenseStatus'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luLicenseStatus', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuLicenseStatus(context, payload) {
      if (context.state.selectedLuLicenseStatus && context.state.selectedLuLicenseStatus.ID) {
        context.dispatch('loadSelectedLuLicenseStatus', context.state.selectedLuLicenseStatus.ID)
      }
    },
    loadSelectedLuLicenseStatus(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuLicenseStatus/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuLicenseStatus', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuLicenseStatus(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuLicenseStatus')
      }, )
    },
    updateLuLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseStatus/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Status updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseStatus')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseStatus`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Status created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseStatus')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuLicenseStatus', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuLicenseStatus', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuLicenseStatus(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuLicenseStatus/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu License Status deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuLicenseStatus')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luLicenseStatus