export default {
  BaseUrl: `/api/`,
  gapiConfig: {
    apiKey: 'AIzaSyDJiSm3cjHtxmkSdx-CUyhbiQ7I_1O-kFk',
    clientId:
      '845319349417-gtkipcvea28plf7ui6d0np0ts7soes35.apps.googleusercontent.com',
    fetch_basic_profile: true,
    scope: 'email profile',
    // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
  },
  identityUrl: `https://identitysts-gcp.wyo.gov`,
}
