import { mapState, mapGetters, mapActions } from 'vuex'

//this is an example of how you can abstract out the mapGetters/mapState/mapActions from your components

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    signingIn: (state) => state.signingIn,
    identityUrl: (state) => state.identityUrl,
    loginType: (state) => state.loginType,
    singleProvider: (state) => state.singleProvider,
  }),

  ...mapGetters('auth', ['isLoggedIn', 'isAdmin', 'isAgUser', 'isBusinessUser', 'isInternalUser']),
}

export const authMethods = mapActions('auth', [
  'logOut',
  'refreshToken',
  'refreshCurrentUser'
])
