import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    //dark: true,
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: colors.blue.lighten3,
        accent: colors.blue.lighten2,
        background: '#fff'
      },
      // To switch to a dark theme, place the colors you want to use within the code below, uncomment
      // and uncomment dark: true above
      // dark: {
      //   primary: colors.indigo,
      //   secondary: colors.indigo.lighten4,
      //   accent: colors.indigo.base,
      // }
    },
  },
})