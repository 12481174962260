export const actions = {
    getTickets(context, payload) {
        const url = 'ticket';
        Vue.prototype.$axios
            .get(url)
            .then(
                (res) => {
                    context.commit('getTickets', res.data);
                },
                (error) => {
                    console.log(error);
                },
            );
    },
};