import Vue from 'vue'

const keyParticipants = {
  state: {
    keyParticipants: [],
    selectedKeyParticipants: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    keyParticipants(state) {
      return state.keyParticipants
    },
    selectedKeyParticipants(state) {
      return state.selectedKeyParticipants
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    keyParticipants(state, payload) {
      state.keyParticipants = payload
    },
    selectedKeyParticipants(state, payload) {
      state.selectedKeyParticipants = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedKeyParticipants(context, payload) {
      context.commit('selectedKeyParticipants', payload)
    },
    loadKeyParticipants(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'KeyParticipants'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('keyParticipants', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedKeyParticipants(context, payload) {
      if (context.state.selectedKeyParticipants && context.state.selectedKeyParticipants.ID) {
        context.dispatch('loadSelectedKeyParticipants', context.state.selectedKeyParticipants.ID)
      }
    },
    loadSelectedKeyParticipants(context, payload) {
      if (context.state.selectedKeyParticipants && context.state.selectedKeyParticipants.ID != payload) {
        context.commit('selected', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'KeyParticipants/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedKeyParticipants', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadKeyParticipants(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadKeyParticipants')
      }, )
    },
    updateKeyParticipants(context, payload){
      return new Promise((resolve, reject) => {
        const url = `KeyParticipants/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Key Participants updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadKeyParticipants')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createKeyParticipants(context, payload){
      return new Promise((resolve, reject) => {
        const url = `KeyParticipants`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Key Participants created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadKeyParticipants')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveKeyParticipants(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateKeyParticipants', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createKeyParticipants', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteKeyParticipants(context, payload){
      return new Promise((resolve, reject) => {
        const url = `KeyParticipants/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Key Participants deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadKeyParticipants')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default keyParticipants