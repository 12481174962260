<!-- BODY LAYOUT -->
<template>
  <div id="app">
    <v-app>
      <Layout>
        <v-content>
          <!--
        $route.fullPath - will always load components as distinct components
        this will allow you to navigate from one instance of a component to another
        (from one item to another) and it will recreate the component so that the data is changed
          -->
          <RouterView :key="$route.fullPath" />
        </v-content>
      </Layout>
    </v-app>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import appConfig from '@src/app.config'
import { authMethods, authComputed } from '@state/helpers'
import mainVue from './router/layouts/main.vue'

export default {
  name: 'App',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  mounted() {
    this.setUpTokenRefresh()
    this.refreshToken()
  },
  computed: { ...authComputed },
  methods: {
    ...authMethods,
    setUpTokenRefresh() {
      try {
        //this refreshes the etsjwt token
        window.setInterval(() => {
          this.refreshToken()
        }, 1000 * 60 * 20)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
  },
  components: { Layout: mainVue },
}
</script>

<!-- STYLES -->
<style lang="scss">
@import '@design';
</style>
