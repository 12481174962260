<template>
  <v-layout>
    <v-snackbar
      class="snackbarStyles"
      v-model="displayError"
      bottom="bottom"
      :timeout="0"
      vertical="vertical"
      :multi-line="true"
      left
    >
      <v-list dense dark>
        <v-list-item v-for="(error, index) in errors" :key="index">
          <v-list-item-content @click="showDetails(error)">
            <v-list-item-title>{{error|snippet}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="ma-0">
            <v-btn class="ma-0" @click="rmError(index)" icon>
              <v-icon color="grey lighten-1">clear</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <br />
      <v-btn color="grey lighten-5" text @click="closeError">Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="dialog" vertical="vertical" :multi-line="true" :timeout="0" right>
      {{errorDetails}}
      <v-btn color="grey lighten-5" text @click="dialog = !dialog">Close</v-btn>
    </v-snackbar>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  page: {
    title: ''
  },
  data () {
    return {
      dialog: false,
      errorDetails: ''
    }
  },
  filters: {
    snippet (text) {
      if (text.toString().length > 80) {
        return text.toString().substring(0, 80) + '...'
      } else {
        return text
      }
    }
  },
  computed: {
        ...mapGetters('errors', [
      'errors', 'errorMessage'
    ]),
    displayError: {
      get () {
        return this.$store.state.errors.displayError
      },
      set (newValue) {
        this.$store.dispatch('errors/setDisplayError', newValue)
      }
    },
  },
  methods: {
    // removeError: call('errors/removeError'),
    removeError () {
      this.$store.dispatch('errors/removeError')
    },
    closeError () {
      this.displayError = false
    },
    rmError (index) {
      this.removeError(index)
    },
    showDetails (msg) {
      this.errorDetails = msg
      this.dialog = true
    }
  },
  watch: {

  },
}
</script>
<style scoped>
</style>