import Vue from 'vue'

const luBusinessType = {
  state: {
    luBusinessType: [],
    selectedLuBusinessType: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luBusinessType(state) {
      return state.luBusinessType
    },
    selectedLuBusinessType(state) {
      return state.selectedLuBusinessType
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luBusinessType(state, payload) {
      state.luBusinessType = payload
    },
    selectedLuBusinessType(state, payload) {
      state.selectedLuBusinessType = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuBusinessType(context, payload) {
      context.commit('selectedLuBusinessType', payload)
    },
    loadLuBusinessType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuBusinessType'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luBusinessType', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuBusinessType(context, payload) {
      if (context.state.selectedLuBusinessType && context.state.selectedLuBusinessType.ID) {
        context.dispatch('loadSelectedLuBusinessType', context.state.selectedLuBusinessType.ID)
      }
    },
    loadSelectedLuBusinessType(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuBusinessType/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuBusinessType', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuBusinessType(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuBusinessType')
      }, )
    },
    updateLuBusinessType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuBusinessType/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Business Type updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuBusinessType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuBusinessType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuBusinessType`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Business Type created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuBusinessType')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuBusinessType(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuBusinessType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuBusinessType', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuBusinessType(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuBusinessType/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Business Type deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuBusinessType')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luBusinessType