import Vue from 'vue'

const licenseDocumentTypes = {
  state: {
    licenseDocumentTypes: [],
    selectedLicenseDocumentTypes: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    licenseDocumentTypes(state) {
      return state.licenseDocumentTypes
    },
    selectedLicenseDocumentTypes(state) {
      return state.selectedLicenseDocumentTypes
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    licenseDocumentTypes(state, payload) {
      state.licenseDocumentTypes = payload
    },
    selectedLicenseDocumentTypes(state, payload) {
      state.selectedLicenseDocumentTypes = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLicenseDocumentTypes(context, payload) {
      context.commit('selectedLicenseDocumentTypes', payload)
    },
    loadLicenseDocumentTypes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseDocumentTypes'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('licenseDocumentTypes', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLicenseDocumentTypes(context, payload) {
      if (context.state.selectedLicenseDocumentTypes && context.state.selectedLicenseDocumentTypes.ID) {
        context.dispatch('loadSelectedLicenseDocumentTypes', context.state.selectedLicenseDocumentTypes.ID)
      }
    },
    loadSelectedLicenseDocumentTypes(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicenseDocumentTypes/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLicenseDocumentTypes', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLicenseDocumentTypes(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLicenseDocumentTypes')
      }, )
    },
    updateLicenseDocumentTypes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseDocumentTypes/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Document Types updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseDocumentTypes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLicenseDocumentTypes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseDocumentTypes`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Document Types created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseDocumentTypes')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLicenseDocumentTypes(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLicenseDocumentTypes', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLicenseDocumentTypes', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLicenseDocumentTypes(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicenseDocumentTypes/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Document Types deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicenseDocumentTypes')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default licenseDocumentTypes