import Vue from 'vue'

const license = {
  state: {
    license: [],
    selectedLicense: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    license(state) {
      return state.license
    },
    selectedLicense(state) {
      return state.selectedLicense
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    license(state, payload) {
      state.license = payload
    },
    selectedLicense(state, payload) {
      state.selectedLicense = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLicense(context, payload) {
      context.commit('selectedLicense', payload)
    },
    loadLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'License'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('license', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLicense(context, payload) {
      if (context.state.selectedLicense && context.state.selectedLicense.ID) {
        context.dispatch('loadSelectedLicense', context.state.selectedLicense.ID)
      }
    },
    loadSelectedLicense(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'License/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLicense', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLicense(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLicense')
      }, )
    },
    updateLicense(context, payload){
      return new Promise((resolve, reject) => {
        const url = `License/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicense')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLicense(context, payload){
      return new Promise((resolve, reject) => {
        const url = `License`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicense')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLicense(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLicense', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLicense', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLicense(context, payload){
      return new Promise((resolve, reject) => {
        const url = `License/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicense')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default license