// import '@babel/polyfill'
import Vue from 'vue'
import './plugins/axios'
import './plugins/google'
// import './plugins/gapi'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from '@router'
import store from '@state/store'
import '@components/_globals'
import '@filters'
import './registerServiceWorker'

window.eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
