import Vue from 'vue'

const licensePeriod = {
  state: {
    licensePeriod: [],
    selectedLicensePeriod: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    licensePeriod(state) {
      return state.licensePeriod
    },
    selectedLicensePeriod(state) {
      return state.selectedLicensePeriod
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    licensePeriod(state, payload) {
      state.licensePeriod = payload
    },
    selectedLicensePeriod(state, payload) {
      state.selectedLicensePeriod = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLicensePeriod(context, payload) {
      context.commit('selectedLicensePeriod', payload)
    },
    loadLicensePeriod(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicensePeriod'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('licensePeriod', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLicensePeriod(context, payload) {
      if (context.state.selectedLicensePeriod && context.state.selectedLicensePeriod.ID) {
        context.dispatch('loadSelectedLicensePeriod', context.state.selectedLicensePeriod.ID)
      }
    },
    loadSelectedLicensePeriod(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LicensePeriod/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLicensePeriod', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLicensePeriod(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLicensePeriod')
      }, )
    },
    updateLicensePeriod(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicensePeriod/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Period updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicensePeriod')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLicensePeriod(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicensePeriod`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Period created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicensePeriod')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLicensePeriod(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLicensePeriod', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLicensePeriod', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLicensePeriod(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LicensePeriod/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'License Period deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLicensePeriod')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default licensePeriod