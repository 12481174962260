import Vue from 'vue'

const luRole = {
  state: {
    luRole: [],
    selectedLuRole: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luRole(state) {
      return state.luRole
    },
    selectedLuRole(state) {
      return state.selectedLuRole
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luRole(state, payload) {
      state.luRole = payload
    },
    selectedLuRole(state, payload) {
      state.selectedLuRole = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuRole(context, payload) {
      context.commit('selectedLuRole', payload)
    },
    loadLuRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuRole'
        context.commit('increaseSearchCount')
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('luRole', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    refreshSelectedLuRole(context, payload) {
      if (context.state.selectedLuRole && context.state.selectedLuRole.ID) {
        context.dispatch('loadSelectedLuRole', context.state.selectedLuRole.ID)
      }
    },
    loadSelectedLuRole(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'LuRole/' + payload
        Vue.prototype.$axios
        .get(url)
        .then(
          (res) => {
            context.commit('selectedLuRole', res.data)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    clearLookupCacheAndReloadLuRole(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuRole')
      }, )
    },
    updateLuRole(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuRole/${ payload.ID }`
        context.commit('saving', true)
        Vue.prototype.$axios
        .put(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Role updated',},{ root: true })
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuRole')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    createLuRole(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuRole`
        context.commit('saving', true)
        Vue.prototype.$axios
        .post(url, payload)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Role created',},{ root: true })
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuRole')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
    saveLuRole(context, payload){
      return new Promise((resolve, reject) => {
        if (payload.ID > 0) {
          context.dispatch('updateLuRole', payload)
          .then((res) => resolve(res), (err) => reject(err))
        } else {
          context.dispatch('createLuRole', payload)
          .then((res) => resolve(res), (err) => reject(err))
        }
      })
    },
    deleteLuRole(context, payload){
      return new Promise((resolve, reject) => {
        const url = `LuRole/${ payload.ID }`
        Vue.prototype.$axios
        .delete(url)
        .then(
          (res) => {
            context.dispatch('messages/toastMessage',
            {color: 'success', message: 'Lu Role deleted',},{ root: true })
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can relaod whatever object you need
            //when the promise resolves in the componenet that called it.
            //context.dispatch('loadLuRole')
            resolve(res)
          },
          (err) => {
            console.log(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          },
        )
      })
    },
  },
}

export default luRole